.map {
    border: 0;
    margin-left:0;
    /* border-radius: 20px;
    border:solid #004C8A 2px; */
    width: 1000px;
    height:525px;
    padding:10px;
    margin-bottom:0;
}

.map-container {
    background-color: #F7F7FA;
    padding:10px;
    margin:30px;
}

iframe .gm-inset-map .gm-inset-light {
    display: none;
}

@media (max-width: 1024px) {
    .map {
        width: 400px;
        height:450px;
    }
  }
  
  @media (max-width: 768px) {
    .map {
        width: 360px;
        height:360px;
    }
  }
  