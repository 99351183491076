.partnership-container {
  display: flex;
  justify-content: space-between;
  /* Adjust spacing between the items */
  align-items: flex-start;
  /* Aligns items at the top */
  gap: 40px;
  margin: 35px;
}

.partnership-item {
  display: flex;
  flex-direction: column;
  /* Stacks text above image */
  align-items: center;
  /* Centers content horizontally */
  width: 50%;
  /* Adjusts width to make them sit side by side */
  padding: 40px;
  background-color: #fff;
}

.partnership-text {
  margin-bottom: 10px;
  /* Adds space between text and image */
  font-size: 1rem;
  text-align: center;
  /* Optional: centers the text */
}

.partnership-image {
  height: 180px;
}

/* Media query for 1024px resolution */
@media (max-width: 1024px) {
  .partnership-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0;
    margin-top: 50px;
  }

  .partnership-item {
    width: 80%;
    /* Increases the width to make each item more prominent */
    padding: 30px;
  }

  .partnership-image {
    height: 180px;
    /* Slightly reduce the image size */
  }
}

/* Media query for 768px resolution */
@media (max-width: 768px) {
  .partnership-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 0;
    margin-top: 50px;
  }

  .partnership-item {
    width: 100%;
    /* Make each item take full width */
    padding: 20px;
  }

  .partnership-text {
    font-size: 0.9rem;
    /* Slightly reduce font size for better readability */
  }

  .partnership-image {
    height: 110px;
    /* Reduce the image size to fit smaller screens */
  }
}