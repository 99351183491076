.reviews-container {
    flex: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    background-color: #fff;
    height:100%;
    align-items: center;
    margin:35px;
    text-align: center;
}

.review {
    height: 50%;
    width:20%;
    border: solid #eee 1px;
    margin:20px;
    border-radius:15px;
}

.review-header {
width:100%;
    top:2rem;
    left:0;
    right:0;
    font-size: x-large;
}

@media (max-width: 1024px) {
    .reviews-container { 
      flex-wrap: wrap;
      height:100%;
      margin-left: 0;
      margin-right: 0;
    }
  
    .review {
    
      width:40%;
      height:100%;
      
    }

    .review-header {
        height:3rem;
    }
  }
  
  @media (max-width: 768px) {
    .reviews-container { 
        flex-wrap: wrap;
        height:100%;
        margin-left: 0;
        margin-right: 0;
      }
    
      .review {
      
        width:90%;
        height:100%;
      }

      .review-header {
        height:3rem;
    }
  }