/* General styles */
.about-me {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height:100%;
    margin: 2rem;
  }
  
.about-me .info p {
  white-space: break-spaces;
  text-align: left;
  margin-left: 20px;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

  .info {
    display: flex;
    align-items: stretch;
    flex: 1;
    background-color: #fff;
  }
  

  .about-me-img {
    height: 22rem;
    object-fit: contain;
  }

  /* Media query for 1024px resolution */
@media (max-width: 1024px) {
  .about-me {
      flex-direction: column;
      margin: 0;
      margin-top: 20px;
  }

  .info {
    display: inline-block;
    padding-bottom: 20px;
  }

  .about-me .info p {
      margin-top: 15px;
  }

  .about-me-img {
      height: 18rem;
  }
}

/* Media query for 768px resolution */
@media (max-width: 768px) {
  .about-me {
      flex-direction: column;
      margin: 0;
      margin-top: 20px;
  }

  .info {
    display: inline-block;
    padding-bottom: 20px;
  }


  .about-me .info p {
      margin-top: 10px;
  }

  .about-me-img {
      height: 15rem;
  }
}