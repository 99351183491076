.App {
  text-align: center;
}

.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: border-bottom 0.3s ease-in-out;
}

.header.scrolled {
  border-bottom: 2px solid #ff9f22;
  /* Adds the border when scrolled */
}

.logo {
  cursor: pointer;
}

.logo img {
  height: 50px;
}

.navBar {
  background-color: #fff;
  padding: 20px;
}

.hovering-actions {
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 10px 10px;
  display: flex;
  z-index: 100000005;
  flex-direction: column;
}

.navLink {
  color: #000;
  margin-right: 30px;
  margin-top: 15px;
  text-decoration: none;
  font-weight: bold;
}

.navLink:hover {
  color: #ff9f22;
}

.activeLink {
  border-bottom: 2px solid #ff9f22;
}

.background {
  /* padding: 3rem; */
  background: #adaeaacf;
  background-image: url("Assets/grayscale.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-blend-mode: screen;
}

body {
  /* font-family: 'Lato', sans-serif; */
  font-family:
    Inter Variable,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    Helvetica,
    sans-serif;


  background: #ADAEAA;
  background-image: url("Assets/grayscale.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-blend-mode: screen;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


header {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F7F7FA;
  color: #000;
  z-index: 10001;
}

i {
  color: #ff9f22;
  padding: 10px;
}

.section {
  /* padding: 20px; */
  /* margin-bottom:10rem; */
}

.blank-section {
  height: 20rem;
}

.round-container {
  background: #fff;
  /* border-radius: 50px; */
  /* padding: 4rem;
  margin-bottom: 2rem; */
}

.alt-round-container {
  background: #fff;
  padding: 4rem;
  /* border-radius: 50px;

  margin-bottom: 2rem; */
}


.hamburger {
  display: none;
  /* Hide by default */
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
}

.closeMenu {
  text-align: center;
  margin-top: 20px;
}

.displayMobile {
  display: none;
}

.displayPc {
  display: flex;
}

@media (max-width: 1280px) {
  .navLink {
    font-size: medium;
    margin-right:20px;
  }
}

@media (max-width: 1024px) {
  .navLinks {
    display: none;
    /* Hide links initially on smaller screens */
    flex-direction: column;
    /* Stack links vertically */
    position: absolute;
    /* Position absolutely */
    top: 60px;
    /* Adjust according to your header height */
    left: 0;
    right: 0;
    background-color: white;
    /* Optional: background for menu */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* Optional shadow */
    z-index: 10;
    /* Ensure it overlays other content */
  }

  .navLink {
    margin-right: 0px;
  }

  .displayMobile {
    display: block;
    background-color: #fff;
  }

  .displayPc {
    display: none;
  }

  .navLinks.open {
    display: flex;
    background: #fff;
    z-index: 1000005;
    position: fixed;
    /* height: 84%; */
    align-content: center;
    /* vertical-align: baseline; */
    bottom: 0;
    margin-top: 60%;
    border-top: solid 15px #ff9f22;
    border-radius: 10px;
  }

  .hamburger {
    display: block;
    /* Show hamburger on smaller screens */
  }
}

@media (max-width: 768px) {
  /* Additional styles for 768px resolution if needed */
}