
.hovering-button {
    background-color: #fff;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border: solid 1px #ccc;
    border-radius: 50px;
    cursor: pointer;
    z-index: 10002;
    padding:10px;
    margin:5px;
  }
  
  .hovering-button:hover {
    background-color: #F7F7FA;
    transform: scale(1.15);
  }

  .hovering-button i {
    font-size:40px;
  }
  