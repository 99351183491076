.drip-container {
    display: flex;
    flex-direction: column;
    gap: 30px; /* Spacing between each section */
    margin: 35px;
    text-align: left;
}

.drip-container li {
    list-style-type: none; 
    padding: 0; 
    margin: 0;
}

.vit-drip-section-center {
    display: block;
    align-items: center;
    background-color: #fff; /* White background for each section */
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for styling */
}

.vit-drip-section-center .la {
    text-align: left;
}


.vit-drip-container {
    display: flex;
    justify-content: space-between; /* Adjust spacing between the items */
    align-items: flex-start; /* Aligns items at the top */
    gap:40px;
  }
  
  .vit-drip-item {
    display: flex;
    flex-direction: column; /* Stacks text above image */
    align-items: center; /* Centers content horizontally */
    width: 50%; /* Adjusts width to make them sit side by side */
    padding:40px;
    background-color: #fff;
  }

  .vit-drip-item_long {
    display: flex;
    flex-direction: column; /* Stacks text above image */
    align-items: center; /* Centers content horizontally */
    width: 100%; /* Adjusts width to make them sit side by side */
    padding:40px;
    background-color: #fff;
  }
  
  .vit-drip-text {
    margin-bottom: 10px; /* Adds space between text and image */
    font-size: 1rem;
    text-align: center; /* Optional: centers the text */
  }
  
  .vit-drip-image {
    height:200px;
  }

  .drip-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px;
}

.card {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 15px;
    max-width: 300px;
    text-align: center;
}

.card-image {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.card-content {
    padding: 15px;
}

.card h2 {
    font-size: 1.5em;
    margin: 10px 0;
}

.card p {
    margin: 10px 0;
}

.card ul {
    list-style-type: none;
    padding: 0;
}

.card ul li {
    margin: 5px 0;
}

/* Media query for 1024px resolution */
@media (max-width: 1024px) {
    .drip-container {
        margin-top: 30px; /* Optional: Adjust margin for smaller screens */
        margin-left: 0px;
        margin-right: 0px;
    }

    .vit-drip-container {
        flex-direction: column; /* Stack items vertically */
    }

    .vit-drip-item {
        width: 100%; /* Full width for items */
        margin-left: 0; /* Set left margin to 0 */
        margin-right: 0; /* Set right margin to 0 */
    }

    .card-container {
        margin: 0; /* Set margin to 0 left and right */
    }

    .card {
        margin-top: 10px; /* Set margin to 0 left and right */
    }
}

/* Media query for 768px resolution */
@media (max-width: 768px) {
    .drip-container {
        margin-top: 30px; /* Optional: Adjust margin for smaller screens */
        margin-left: 0px;
        margin-right: 0px;
    }

    .vit-drip-container {
        flex-direction: column; /* Stack items vertically */
    }

    .vit-drip-item {
        width: 100%; /* Full width for items */
        margin-left: 0; /* Set left margin to 0 */
        margin-right: 0; /* Set right margin to 0 */
    }

    .card-container {
        margin: 0; /* Set margin to 0 left and right */
    }

    .card {
        margin-top: 10px; /* Set margin to 0 left and right */
    }
}