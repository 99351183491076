.container {
    display: flex;
    justify-content: space-evenly;
    flex:auto;
    flex-direction: column;
}

.container div {
    margin-left: 10px;
    margin-right: 10px;
}

.contact-us-container {
    
}

.contact-us-container div {
    display: inline-block;
    font-size: medium;
}

.contact-us-container a {
    color: #000;
    text-decoration: none;
    font-size:medium;
}

.contact-us-container a:hover {
    color: #ff9f22;
    text-decoration: none;
}

a.a-button:hover {
    color: #000;
}

.container div.icon-container {
    margin: 0;
    cursor: pointer;
}

i.fa-brands {
    font-size: large;
} 

.a-button {
    padding: 10px 20px;
    background-color: #ff9f22;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.a-button:hover {
    background-color: #cc7f1b;
}

i.fa-brands.large-icon {
    font-size: x-large;
}

/* Media query for 1024px resolution */
@media (max-width: 1024px) {
    .container {
        flex-direction: column; /* Stack the items vertically */
        justify-content: center; /* Center the items */
        align-items: center; /* Aligns items centrally */
    }

    .container div {
        margin-left: 0; /* Reduce the margin to save space */
        margin-right: 0;
        margin-bottom: 20px; /* Add space between the stacked items */
    }

    .contact-us-container div {
        font-size: larger; /* Increase font size slightly for better readability */
    }

    .a-button {
        padding: 8px 16px; /* Reduce padding to fit smaller screens */
        font-size: 1rem; /* Adjust button font size */
    }

    i.fa-brands {
        font-size: medium; /* Slightly decrease icon size */
    }
}

/* Media query for 768px resolution */
@media (max-width: 768px) {
    .container {
        flex-direction: column; /* Stack the items vertically */
        justify-content: center;
        align-items: center;
    }

    .container div {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 15px; /* Add more spacing between items */
    }

    .contact-us-container div {
        font-size: large; /* Increase font size for readability on smaller screens */
    }

    .contact-us-container a {
        font-size: large; /* Make links more prominent */
    }

    .a-button {
        padding: 6px 12px; /* Further reduce padding */
        font-size: 0.9rem; /* Adjust font size for buttons */
    }

    i.fa-brands {
        font-size: medium; /* Slightly reduce the icon size */
    }
}