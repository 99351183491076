.rehab-container {
    display: flex;
    flex-direction: column;
    gap: 30px; /* Spacing between each section */
    margin: 35px;
}

.rehab-section {
    display: grid;
    grid-template-columns: 2fr 1fr; /* Split into two equal columns */
    align-items: center;
    background-color: #fff; /* White background for each section */
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for styling */
    text-align: left;
}

.rehab-section-wide {
    display: grid;
    grid-template-columns: 1fr 3fr; /* Split into two equal columns */
    align-items: center;
    background-color: #fff; /* White background for each section */
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for styling */
    text-align: left;
}

.rehab-section-wide > .placeholder-image {
    max-width: 400px;
}

.rehab-section > .placeholder-image {
    max-width: 500px;
}


.rehab-section-center {
    display: block;
    align-items: center;
    background-color: #fff; /* White background for each section */
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for styling */
}

.rehab-section-center .la {
    text-align: left;
}

.placeholder-image {
    width: 100%;
    height: auto;
    max-width: 300px; /* Restrict maximum image size */
    border-radius: 10px;
}

.text-content {
    padding: 10px 20px;
}

.text-content-center {
    text-align: center;
}

.text-content h2,
.text-content h3 {
    margin-bottom: 15px;
}

.text-content ul {
    list-style: none;
    padding-left: 0;
}

.text-content ul li {
    margin-bottom: 10px;
}

.text-content p {
    margin: 10px 0;
}

.text-content strong {
    font-weight: bold;
}

/* Media query for 1024px resolution */
@media (max-width: 1024px) {
    .rehab-container {
        gap: 25px; /* Adjust the gap between sections */
        margin: 0;
    }

    .rehab-section, .rehab-section-wide {
        grid-template-columns: 1fr; /* Stack the columns vertically */
        text-align: center; /* Center text for better layout on smaller screens */
    }
}

/* Media query for 768px resolution */
@media (max-width: 768px) {
    .rehab-container {
        gap: 20px; /* Further adjust gap between sections */
        margin: 0;
    }

    
    .rehab-section, .rehab-section-wide {
        grid-template-columns: 1fr; /* Stack columns vertically */
        padding: 15px; /* Reduce padding */
        text-align: center; /* Center content for narrow screens */
    }


    .text-content {
        padding: 5px 10px; /* Reduce padding inside text content */
    }

    .text-content h2, .text-content h3 {
        margin-bottom: 10px; /* Adjust spacing for headers */
    }
}